import SecureDataExchangeContainer from "../components/SecureDataExchangeContainer";
import Header from "../components/Header";
import SecureCommunicationContainer from "../components/SecureCommunicationContainer";
import ServerOwnerContainer from "../components/ServerOwnerContainer";
import GlobalContainer from "../components/GlobalContainer";
import EncryptionContainer from "../components/EncryptionContainer";
import IdentityCardContainer from "../components/IdentityCardContainer";
import PlansContainer from "../components/PlansContainer";
import ContactContainer from "../components/ContactContainer";
import TriosContainer from "../components/TriosContainer";
import styles from "../css/main_css/Homepage3.module.css";
import { useRef } from "react";

const Homepage3 = () => {

  const gioi_thieuRef = useRef(null);
  const giai_phapRef = useRef(null);
  const mo_hinhRef = useRef(null);
  const cong_ngheRef = useRef(null);
  const bang_giaRef = useRef(null);

  return (
    <div className={styles.homepage3}>

      <div className={styles.heroSection} ref={gioi_thieuRef}>
        <img className={styles.bgIcon} alt="" src="../bg.svg" />
        <img className={styles.decorIcon} alt="" src="../decor.svg" />
        <Header
          className={styles.header}
          gioi_thieuRef={gioi_thieuRef}
          giai_phapRef={giai_phapRef}
          mo_hinhRef={mo_hinhRef}
          cong_ngheRef={cong_ngheRef}
          bang_giaRef={bang_giaRef}
        />
        <SecureDataExchangeContainer/>
        
      </div>


      <div className={styles.tnhNng} ref={giai_phapRef}>
        <img className={styles.tnhNngChild} alt="" src="../group-85442.svg" />
        <SecureCommunicationContainer />
        <img className={styles.illusIcon} alt="" src="../illus.svg" />
        <ServerOwnerContainer/>
      </div>


      <div className={styles.mHnh} ref={mo_hinhRef}>
        <img className={styles.mHnhItem} alt="" src="../polygon-7.svg" />
        <img className={styles.decorIcon1} alt="" src="../decor1.svg" />

        <div className={styles.title}>
          <b className={styles.giiPhpTon}>Mô hình tổng thể</b>
          <div className={styles.triosGiaiQuytCacVnWrapper}>
            <div className={styles.triosGiaiQuyt}>
              Mô hình tổng thể của ứng dụng Trios giúp vận hành an toàn và dễ
              dàng. Các mô hình được thiết kế linh hoạt phù hợp với cấu trúc tổ
              chức khác nhau, đảm bảo tối ưu về chi phí và vận hành.
            </div>
          </div>
        </div>

        <div className={styles.mHnhTngThNgDngParent}>

          <div >
            <img src="mhTongThe.svg" className={styles.container} />
          </div>

          <div className={styles.containerMobile}>
            <img src="GlobalContainerMobile.svg" />
          </div>

          <div className={styles.polygonParent}>
            <img className={styles.frameChild} alt="" src="../polygon-61.svg" />
            <div className={styles.feature1}>
              <div className={styles.ilus3Wrapper}>
                <img className={styles.ilus3Icon} alt="" src="../ilus-3.svg" />
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameParent}>
                  <b className={styles.sHuLm}>Mô hình toàn cầu</b>
                </div>
                <div className={styles.triosUcTrinKhaiTrenGroup}>
                  <div className={styles.triosUcTrin}>
                    Mô hình phù hợp với trao đổi thông tin công việc toàn cầu
                  </div>
                  <b className={styles.readMore}>Read more</b>
                </div>
              </div>
            </div>
            <div className={styles.feature1}>
              <div className={styles.ilus3Wrapper}>
                <img className={styles.ilus3Icon} alt="" src="../ilus-14.svg" />
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameParent}>
                  <b className={styles.sHuLm}>Mô hình chi nhánh</b>
                </div>
                <div className={styles.triosUcTrinKhaiTrenGroup}>
                  <div className={styles.triosUcTrin}>
                    Trao đổi thông tin công việc trong một cơ quan, tổ chức
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.feature1}>
              <div className={styles.ilus3Wrapper}>
                <img className={styles.ilus3Icon} alt="" src="../ilus-51.svg" />
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameParent}>
                  <b className={styles.sHuLm}>Mô hình diện rộng</b>
                </div>
                <div className={styles.triosUcTrinKhaiTrenGroup}>
                  <div className={styles.triosUcTrin}>
                    <p className={styles.traoIThng}>
                      Trao đổi thông tin công việc giữa nhiều cơ quan, tổ chức</p>
                  </div>
                  <b className={styles.readMore}>Read more</b>
                </div>
              </div>
            </div>
            
            <div className={styles.feature1}>
              <div className={styles.ilus3Wrapper}>
                <img
                  className={styles.ilus3Icon}
                  alt=""
                  src="../ilus-10.svg"
                />
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameParent}>
                  <b className={styles.sHuLm}>Mô hình ad-hoc</b>
                </div>
                <div className={styles.triosUcTrinKhaiTrenGroup}>
                    <div className={styles.triosUcTrin}>
                      {`Dành cho kết nối trong điều kiện không có internet, không kết nối tới máy chủ `}
                    </div>
                  <b className={styles.readMore}>Read more</b>
                </div>
              </div>
              <div className={styles.angPhtTrinWrapper}>
                <div className={styles.angPhtTrin}>Đang phát triển</div>
              </div>
            </div>
              
            
          </div>
        </div>
        <img className={styles.decor1Icon} alt="" src="../decor-1.svg" />
      </div>




      <div className={styles.cnSd} ref={cong_ngheRef}>
        <div className={styles.title1}>
          <b className={styles.giiPhpTon}>Công nghệ sử dụng</b>
          <div className={styles.triosGiaiQuytCacVnWrapper}>
            <div className={styles.triosGiaiQuyt}>
              Mô hình tổng thể của ứng dụng Trios giúp vận hành an toàn và dễ
              dàng. Các mô hình được thiết kế linh hoạt phù hợp với cấu trúc tổ
              chức khác nhau, đảm bảo tối ưu về chi phí và vận hành.
            </div>
          </div>
        </div>

        <EncryptionContainer
          ellipseImageUrl="../ellipse-18.svg"
          signalProtocolImageUrl="../ellipse-19.svg"
          groupImageUrl="../group-85445.svg"
          ellipseImageUrl1="../advance-encrytion-standard-2@2x.png"
          signalProtocolImageUrl1="../signal-protocol-2.svg"
          propTop="14.82%"
          propBottom="-14.82%"
          propTop1="14.82%"
          propBottom1="9.43%"
          propHeight="42.16%"
          propTop2="24.45%"
        />
        
        <img
          className={styles.cngNghSDngChild}
          alt=""
          src="../frame-86734.svg"
        />
        <img className={styles.cngNghSDngItem} alt="" src="../polygon-2.svg" />
      </div>


  

      <ContactContainer/>
      
      <TriosContainer
        group85457="../group-85457.svg"
        logoTrios="../logo-trios1.svg"
        download="../download2.svg"
        download1="../download3.svg"
        group3982="../group-3982.svg"
      />
    </div>
  );
};

export default Homepage3;
