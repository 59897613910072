import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Homepage3 from "./Homepage3";

const AppLink = () => {
    const location = useLocation();

    useEffect(() => {
        var fallbackLink = new URLSearchParams(location.search).get("deeplink_url");
        var isiOS =
                navigator.userAgent.match("iPad") ||
                navigator.userAgent.match("iPhone") ||
                navigator.userAgent.match("iPod"),
            isAndroid = navigator.userAgent.match("Android");

        if (isiOS || isAndroid) {
            document.getElementById("loader").src = fallbackLink;

            fallbackLink = isAndroid
                ? "https://play.google.com/store/apps/details?id=com.bglobal.trios&referrer="+location.search
                : "https://apps.apple.com/vn/app/trios/id6443949955";
        }

        window.setTimeout(function () {
            if (isiOS || isAndroid) {
                console.log(fallbackLink)
                setTimeout(function () {
                    window.location.replace(fallbackLink);
                }, 5000);
            } else {
                window.location.replace(fallbackLink);
                window.setTimeout(function () {
                    window.location.replace("/");
                }, 3000);
            }
        }, 1000);
    }, []);

    return (<div>
        <Homepage3></Homepage3>
        <iframe id={"loader"}></iframe>
    </div>);
};

export default AppLink;
