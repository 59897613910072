import { useMemo, useEffect } from "react";
import styles from "../css/component_css/RegistrationSuccessPopup.module.css";

const ErrorPopup = (props) => {

  useEffect(() => {
    if (props.openError) {
      document.body.style.overflow = 'hidden';
    } else {
      if (props.errorStatus==="Gửi không thành công"){
        document.body.style.overflow = 'unset';
      }
    }
  }, [props.openError]);

  return (props.openError) ? (
    <div className={styles.popUp} id="ErorrPopup">
      <div className={styles.closeWrapper}>
        <img className={styles.closeIcon} alt="" src="../close.svg" />
      </div>
      <div className={styles.contentParent}>
        <div className={styles.content}>
          <div className={styles.image}>
            <img
              className={styles.checkmarkCircleIcon}
              alt=""
              src="../errorpopup.png"
            />
          </div>
          <div className={styles.content1}>
            <b className={styles.giThnhCng}>{props.errorStatus}</b>
            <div className={styles.tinNhnCaContainer}>
              <p className={styles.thngTinNg}>{props.errorMessage} </p>
              <p className={styles.cmNBn}>Vui lòng nhập lại thông tin!</p>
            </div>
          </div>
        </div>
        <div className={styles.buttons} onClick={props.onCloseError}>
          <div className={styles.buttonDesktop} >
            <b className={styles.tiTrios}>OK</b>
          </div>
        </div>
      </div>
    </div>
  ) : "";
};

export default ErrorPopup;
