import { useMemo } from "react";
import styles from "../css/component_css/ServerOwnerContainer.module.css";

const ServerOwnerContainer = ({}) => {
  const frameStyle = useMemo(() => {
    return {
      zIndex: "3",
    };
  }, ["3"]);

  return (
    <div className={styles.frame} style={frameStyle}>
      <div className={styles.feature1Parent}>
        
        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-6.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>
                Sở hữu, làm chủ máy chủ và toàn bộ dữ liệu
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>
                Trios được triển khai trên hệ thống máy chủ của đơn vị hoặc do đơn vị quản lý. 
                Vì vậy, đơn vị làm chủ toàn bộ thông tin, dữ liệu trong hệ thống.
                </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>

        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-4.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>
                Dữ liệu chỉ được lưu trữ tại máy chủ và thiết bị đầu cuối
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>
                Thông tin cuộc gọi, dữ liệu chia sẻ chỉ được lưu trữ tại máy chủ
                và thiết bị đầu cuối của người dùng, không chia sẻ ra ngoài.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>

        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-5.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>
                Ngăn chặn truy cập trái phép từ thiết bị ngoài hệ thống
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>
                Tất cả các thiết bị đầu cuối được kiểm tra, xác minh trước khi
                được sử dụng hệ thống, ngăn chặn các truy cập trái phép.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.feature1Parent}>
        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-12.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>Dữ liệu được mã hóa trước khi gửi đi</b>
              <b className={styles.sHuLm1}>
                Sở hữu, làm chủ máy chủ và toàn bộ dữ liệu
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>
                Thông tin, dữ liệu được mã hóa trước khi người dùng gửi đi, điều
                này đảm bảo chống nghe lén và quản trị viên hệ thống không đọc
                được nội dung.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-8.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>
                Dữ liệu được bảo vệ từ lớp ứng dụng, giao vận đến vật lý
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>
                Dữ liệu trao đổi được bảo vệ từ lớp ứng dụng, giao vận đến vật
                lý, qua đó ngăn chặn được các tấn công gây nhiễu.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
        <div className={styles.feature1}>
          <div className={styles.ilus11Wrapper}>
            <img className={styles.ilus11Icon} alt="" src="../ilus-21.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameParent}>
              <b className={styles.sHuLm}>Các tính năng thân thiện, quen thuộc với người dùng.</b>
              <b className={styles.sHuLm1}>
                Sở hữu, làm chủ máy chủ và toàn bộ dữ liệu
              </b>
            </div>
            <div className={styles.triosUcTrinKhaiTrenGroup}>
              <div className={styles.triosUcTrin}>Các tính năng trao đổi công việc đều thân thiện và quen thuộc với người dùng như: nhắn tin, gửi file, gọi âm thanh, video, họp trực tuyến,...</div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerOwnerContainer;
