import styles from "../css/component_css/TriosContainer.module.css";

const TriosContainer = ({
  group85457,
  logoTrios,
  download,
  download1,
  group3982,
}) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.footerTrios}>
      <img className={styles.footerTriosChild} alt="" src="../polygon-3.svg" />
      <div className={styles.footer}>
        <img className={styles.footerChild} alt="" src={group85457} />
        <img className={styles.logoTriosIcon} alt="" src={logoTrios} />
        <div className={styles.infor}>
          <div className={styles.company}>
            <div className={styles.mtSnPhmCaParent}>
              <b className={styles.mtSnPhm}>Một sản phẩm của</b>
              <b className={styles.cngTyC}>Công ty Cổ phần bGlobal</b>
            </div>
            <div className={styles.infor1Parent}>
              <div className={styles.infor1}>
                <div className={styles.phng702Tng}>
                  Phòng 702, Tầng 7, Số 2 ngõ 219 Trung Kính, Phường Yên Hoà,
                  Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam
                </div>
              </div>
              <div className={styles.infor2}>
                <div className={styles.phng702Tng1}>
                  Contact@bglobalcorp.com
                </div>
              </div>
              <div className={styles.infor1}>
                <div className={styles.phng702Tng}>+84 (0) 866596247</div>
              </div>
            </div>
            <div className={styles.ionqrCodeSharpParent}>
              <img
                className={styles.ionqrCodeSharpIcon}
                alt=""
                src="../ionqrcodesharp.svg"
              />
              <a href="https://apps.apple.com/vn/app/trios/id6443949955" target="_blank"> <img className={styles.downloadIcon} alt="" src={download} /> </a>
              <a href="https://play.google.com/store/apps/details?id=com.bglobal.trios&hl=en" target="_blank"> <img className={styles.downloadIcon} alt="" src={download1} /> </a>
            </div>
          </div>
          {/* <div className={styles.menu1}>
            <div className={styles.title}>
              <b className={styles.mtSnPhm1}>Một sản phẩm của</b>
              <b className={styles.vTrios}>Về Trios</b>
            </div>
            <div className={styles.menu}>
              <div className={styles.infor2}>
                <a href="" className={styles.phng702Tng1}>Giải pháp</a>
              </div>
              <div className={styles.infor2}>
                <a href="" className={styles.phng702Tng1}>Trang chủ</a>
              </div>
              <div className={styles.infor1}>
                <a href="" className={styles.phng702Tng1}>Bảng giá</a>
              </div>
              <a href="" className={styles.phng702Tng1}>Trợ giúp</a>
            </div>
          </div> */}
          {/* <div className={styles.menu1}>
            <div className={styles.title}>
              <b className={styles.mtSnPhm1}>Một sản phẩm của</b>
              <b className={styles.vTrios}>Chính sách</b>
            </div>
            <div className={styles.infor2Parent}>
              <div className={styles.infor1}>
                <a href="#" className={styles.phng702Tng1}>Thanh toán</a>
              </div>
              <div className={styles.infor1}>
                <a href="#" className={styles.phng702Tng1}>Bảo hành</a>
              </div>
              <div className={styles.infor1}>
                <a href="#" className={styles.phng702Tng1}>Bảo mật thông tin</a>
              </div>
              <div className={styles.infor1}>
                <a href="#" className={styles.phng702Tng1}>Xử lý khiếu nại</a>
              </div>
            </div>
          </div> */}
          <div className={styles.menu3}>
            <div className={styles.title}>
              <b className={styles.mtSnPhm1}>Một sản phẩm của</b>
              <b className={styles.vTrios}>Kết nối</b>
            </div>
            <div className={styles.groupParent}>
              <a href="https://www.facebook.com/bglobalcorp" target="_blank"> <img  alt="" src="facebook.svg" /> </a>
              <img  alt="" src="rectangle70.svg" />
              <a href="https://www.linkedin.com/company/bglobal-jsc/" target="_blank"> <img  alt="" src="linkedin.svg" /> </a>
              
              {/* <div className={styles.bCngThng}>
                <img className={styles.image182Icon} alt="" src="../image-182@2x.png" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.up} onClick={scrollToTop}>
        <div className={styles.upChild} />
        <img
          className={styles.chevronLeftIcon}
          alt=""
          src="../chevronleft.svg"
        />
      </div>
      <div className={styles.copyRight}>
        <div className={styles.copyrightBglobal}>
          Copyright © bGlobal JSC. All Rights Reserved. Giấy chứng nhận ĐKKD số
          0108772805 do SKHĐT Hà Nội cấp ngày 07/06/2019.
        </div>
      </div>
    </div>
  );
};

export default TriosContainer;
