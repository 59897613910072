import styles from "../css/component_css/SecureDataExchangeContainer.module.css";

const policy =`<div>
    <title>Chính sách bảo mật thông tin</title>
    <h1 style="text-align: center;"><strong>Ch&iacute;nh s&aacute;ch bảo mật th&ocirc;ng tin</strong></h1>
    <p>Trios t&ocirc;n trọng những th&ocirc;ng tin c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng. Ch&uacute;ng t&ocirc;i hiểu rằng Qu&yacute; Kh&aacute;ch h&agrave;ng rất quan t&acirc;m đến việc những th&ocirc;ng tin c&aacute; nh&acirc;n m&agrave; Kh&aacute;ch h&agrave;ng đ&atilde; tin cậy cung cấp cho ch&uacute;ng t&ocirc;i được bảo mật v&agrave; sử dụng ra sao.</p>
    <p>Trios đảm bảo sẽ sử dụng th&ocirc;ng tin Kh&aacute;ch h&agrave;ng một c&aacute;ch hợp l&yacute;, c&oacute; c&acirc;n nhắc để kh&ocirc;ng ngừng cải thiện chất lượng dịch vụ v&agrave; đem lại cho Qu&yacute; Kh&aacute;ch h&agrave;ng những trải nghiệm tuyệt vời sau khi sử dụng phần mềm Trios. H&atilde;y đọc v&agrave; t&igrave;m hiểu về Ch&iacute;nh s&aacute;ch bảo vệ th&ocirc;ng tin sau đ&acirc;y.</p>
    <ol>
        <li><strong> Mục đ&iacute;ch thu thập v&agrave; phạm vi sử dụng th&ocirc;ng tin</strong></li>
    </ol>
    <p>Mục đ&iacute;ch của việc thu thập th&ocirc;ng tin l&agrave; nhằm x&acirc;y dựng Trios trở th&agrave;nh một phần mềm trao đổi th&ocirc;ng tin an to&agrave;n mang lại nhiều tiện &iacute;ch nhất cho Người ti&ecirc;u d&ugrave;ng. V&igrave; thế, việc sử dụng th&ocirc;ng tin sẽ phục vụ những hoạt động sau:</p>
    <ul>
        <li>Cung cấp c&aacute;c dịch vụ đến Người ti&ecirc;u d&ugrave;ng;</li>
        <li>Gửi th&ocirc;ng b&aacute;o về c&aacute;c hoạt động giao dịch giữa Người ti&ecirc;u d&ugrave;ng v&agrave; Nh&agrave; cung cấp dịch vụ;</li>
        <li>Ngăn chặn c&aacute;c hoạt động ph&aacute; hủy t&agrave;i khoản hoặc c&aacute;c hoạt động giả mạo Người ti&ecirc;u d&ugrave;ng Trios;</li>
        <li>Li&ecirc;n hệ với c&aacute;c Người ti&ecirc;u d&ugrave;ng trong những trường hợp đặc biệt;</li>
    </ul>
    <p>Trios c&oacute; tr&aacute;ch nhiệm hợp t&aacute;c, cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n của Người ti&ecirc;u d&ugrave;ng khi c&oacute; y&ecirc;u cầu từ cơ quan ph&aacute;p luật bao gồm: Viện kiểm s&aacute;t, t&ograve;a &aacute;n, cơ quan c&ocirc;ng an điều tra li&ecirc;n quan đến h&agrave;nh vi vi phạm ph&aacute;p luật n&agrave;o đ&oacute; của Người ti&ecirc;u d&ugrave;ng.</p>
    <p>Ngo&agrave;i ra, kh&ocirc;ng ai c&oacute; quyền x&acirc;m phạm v&agrave;o th&ocirc;ng tin c&aacute; nh&acirc;n của Người ti&ecirc;u d&ugrave;ng.</p>
    <ol start="2">
        <li><strong> Phạm vi sử dụng th&ocirc;ng tin</strong></li>
    </ol>
    <p>C&ocirc;ng ty sẽ chỉ sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n thu thập được cho một hoặc tất cả c&aacute;c mục đ&iacute;ch sau đ&acirc;y:</p>
    <ul>
        <li>Hỗ trợ kh&aacute;ch h&agrave;ng.</li>
        <li>Cung cấp th&ocirc;ng tin li&ecirc;n quan đến sản phẩm, chăm s&oacute;c kh&aacute;ch h&agrave;ng.</li>
        <li>Cung cấp dịch vụ v&agrave; th&ocirc;ng tin qua trang web của ch&uacute;ng t&ocirc;i theo y&ecirc;u cầu của người ti&ecirc;u dung.</li>
        <li>Ch&uacute;ng t&ocirc;i c&oacute; thể sẽ gửi th&ocirc;ng tin sản phẩm, dịch vụ mới, th&ocirc;ng tin về c&aacute;c sự kiện sắp tới hoặc th&ocirc;ng tin tuyển dụng nếu qu&yacute; kh&aacute;ch đăng k&iacute; nhận email th&ocirc;ng b&aacute;o.</li>
        <li>Ngo&agrave;i ra, ch&uacute;ng t&ocirc;i sẽ sử dụng th&ocirc;ng tin bạn cung cấp để hỗ trợ quản l&yacute; t&agrave;i khoản kh&aacute;ch h&agrave;ng; x&aacute;c nhận v&agrave; thực hiện c&aacute;c giao dịch kh&aacute;c c&oacute; li&ecirc;n quan.</li>
    </ul>
    <ol start="3">
        <li><strong> Thời gian lưu trữ th&ocirc;ng tin</strong></li>
    </ol>
    <p>C&ocirc;ng ty sẽ chỉ lưu giữ Dữ liệu C&aacute; nh&acirc;n của Bạn chừng n&agrave;o cần thiết cho c&aacute;c mục đ&iacute;ch được n&ecirc;u trong Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y. Ch&uacute;ng t&ocirc;i sẽ lưu giữ v&agrave; sử dụng Dữ liệu C&aacute; nh&acirc;n của Bạn trong phạm vi cần thiết để tu&acirc;n thủ c&aacute;c nghĩa vụ ph&aacute;p l&yacute; của ch&uacute;ng t&ocirc;i (v&iacute; dụ: nếu ch&uacute;ng t&ocirc;i được y&ecirc;u cầu giữ lại dữ liệu của bạn để tu&acirc;n thủ luật hiện h&agrave;nh), giải quyết tranh chấp v&agrave; thực thi c&aacute;c thỏa thuận v&agrave; ch&iacute;nh s&aacute;ch ph&aacute;p l&yacute; của ch&uacute;ng t&ocirc;i.</p>
    <p>C&ocirc;ng ty cũng sẽ giữ lại Dữ liệu sử dụng cho c&aacute;c mục đ&iacute;ch đ&aacute;nh gi&aacute; nội bộ. Dữ liệu sử dụng thường được lưu giữ trong một khoảng thời gian ngắn hơn, ngoại trừ khi dữ liệu n&agrave;y được sử dụng để tăng cường bảo mật hoặc để cải thiện chức năng của Dịch vụ của ch&uacute;ng t&ocirc;i hoặc Ch&uacute;ng t&ocirc;i c&oacute; nghĩa vụ ph&aacute;p l&yacute; phải lưu giữ dữ liệu n&agrave;y trong khoảng thời gian d&agrave;i hơn.</p>
    <ol start="4">
        <li><strong> Những người hoặc tổ chức c&oacute; thể được tiếp cận với th&ocirc;ng tin đ&oacute;</strong></li>
    </ol>
    <p>Ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n của bạn trong c&aacute;c trường hợp sau:</p>
    <ul>
        <li><strong>Với c&aacute;c Chi nh&aacute;nh:&nbsp;</strong>Ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ th&ocirc;ng tin của Bạn với c&aacute;c chi nh&aacute;nh của Ch&uacute;ng t&ocirc;i, trong trường hợp đ&oacute;, ch&uacute;ng t&ocirc;i sẽ y&ecirc;u cầu c&aacute;c chi nh&aacute;nh đ&oacute; tu&acirc;n theo Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y. C&aacute;c chi nh&aacute;nh bao gồm c&ocirc;ng ty mẹ của Ch&uacute;ng t&ocirc;i v&agrave; bất kỳ c&ocirc;ng ty con n&agrave;o kh&aacute;c, c&aacute;c đối t&aacute;c li&ecirc;n doanh hoặc c&aacute;c c&ocirc;ng ty kh&aacute;c m&agrave; Ch&uacute;ng t&ocirc;i kiểm so&aacute;t hoặc nằm dưới sự kiểm so&aacute;t chung của Ch&uacute;ng t&ocirc;i.</li>
        <li><strong>Với c&aacute;c đối t&aacute;c Kinh doanh:&nbsp;</strong>Ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ th&ocirc;ng tin của Bạn với c&aacute;c đối t&aacute;c kinh doanh của Ch&uacute;ng t&ocirc;i để cung cấp cho Bạn c&aacute;c sản phẩm, dịch vụ hoặc chương tr&igrave;nh khuyến m&atilde;i nhất định.</li>
        <li><strong>Với những người d&ugrave;ng kh&aacute;c:&nbsp;</strong>chỉ khi Bạn chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n hoặc tương t&aacute;c ở c&aacute;c khu vực c&ocirc;ng cộng với những người d&ugrave;ng kh&aacute;c, những th&ocirc;ng tin đ&oacute; c&oacute; thể được tất cả người d&ugrave;ng xem v&agrave; c&oacute; thể được ph&acirc;n phối c&ocirc;ng khai ra b&ecirc;n ngo&agrave;i. Nếu Bạn tương t&aacute;c với những người d&ugrave;ng kh&aacute;c hoặc đăng k&yacute; th&ocirc;ng qua Dịch vụ truyền th&ocirc;ng x&atilde; hội của b&ecirc;n thứ ba, c&aacute;c li&ecirc;n hệ của Bạn tr&ecirc;n Dịch vụ truyền th&ocirc;ng x&atilde; hội của b&ecirc;n thứ ba c&oacute; thể thấy t&ecirc;n, hồ sơ, ảnh v&agrave; m&ocirc; tả hoạt động của Bạn. Tương tự, những người d&ugrave;ng kh&aacute;c sẽ c&oacute; thể xem m&ocirc; tả hoạt động của Bạn, giao tiếp với Bạn v&agrave; xem hồ sơ của Bạn.</li>
        <li><strong>Đối với việc chuyển giao Doanh nghiệp:&nbsp;</strong>Ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ hoặc chuyển giao th&ocirc;ng tin c&aacute; nh&acirc;n của Bạn li&ecirc;n quan đến, hoặc trong qu&aacute; tr&igrave;nh đ&agrave;m ph&aacute;n, bất kỳ việc s&aacute;p nhập, b&aacute;n t&agrave;i sản của C&ocirc;ng ty, t&agrave;i trợ hoặc mua lại to&agrave;n bộ hoặc một phần hoạt động kinh doanh của ch&uacute;ng t&ocirc;i cho một c&ocirc;ng ty kh&aacute;c.</li>
        <li>C&aacute;c cơ quan nh&agrave; nước c&oacute; thẩm quyền.</li>
    </ul>
</div>`;

const PolicyContentContainer = ({}) => {

    return (
        <>
            <div className={styles.headerPolicy}>
                <div >
                    <div dangerouslySetInnerHTML={{ __html: policy }} />

                </div>
            </div>

        </>
    );
};

export default PolicyContentContainer;
