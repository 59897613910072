import { useMemo } from "react";
import styles from "../css/component_css/EncryptionContainer.module.css";

const EncryptionContainer = ({
  ellipseImageUrl,
  signalProtocolImageUrl,
  groupImageUrl,
  ellipseImageUrl1,
  signalProtocolImageUrl1,
  propTop,
  propBottom,
  propTop1,
  propBottom1,
  propHeight,
  propTop2,
}) => {
  const ellipseIconStyle = useMemo(() => {
    return {
      top: propTop,
      bottom: propBottom,
    };
  }, [propTop, propBottom]);

  const ellipseIcon1Style = useMemo(() => {
    return {
      top: propTop1,
      bottom: propBottom1,
    };
  }, [propTop1, propBottom1]);

  const groupIconStyle = useMemo(() => {
    return {
      height: propHeight,
      top: propTop2,
    };
  }, [propHeight, propTop2]);

  return (
    <div className={styles.tech1Parent}>
      <div className={styles.tech2}>
        <div className={styles.e2eEncryptionParent}>
          <img
            className={styles.e2eEncryption}
            alt=""
            src="mhe2e.svg"
          />
          <div className={styles.frameParent}>
            <div className={styles.mHaUCuiE2eeWrapper}>
              <b className={styles.mHaU}>Mã hóa đầu cuối (E2EE)</b>
            </div>
            <div className={styles.lPhngThcMHaMChNParent}>
              <div className={styles.lPhngThc}>
                Là phương thức mã hóa mà chỉ người nhận và gửi có thể hiểu được
                thông điệp mã hóa, không ai biết được những nội dung đã và đang
                truyền tải, kể cả những nhà cung cấp dịch vụ Internet.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tech2}>
        <div className={styles.e2eEncryptionParent}>
          <img
            className={styles.e2eEncryption}
            alt=""
            src={ellipseImageUrl1}
          />
          <div className={styles.frameParent}>
            <div className={styles.mHaUCuiE2eeWrapper}>
              <b className={styles.mHaU}>Mã hóa AES</b>
            </div>
            <div className={styles.lPhngThcMHaMChNParent}>
              <div className={styles.lPhngThc}>
                Mã hóa AES là một trong những giao thức mã hóa tốt nhất hiện có,
                vì nó kết hợp hoàn hảo giữa tốc độ và bảo mật, được ứng dụng
                trong chia sẻ file tài liệu, hình ảnh, video.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tech2}>
        <div className={styles.e2eEncryptionParent}>
          <img
            className={styles.e2eEncryption}
            alt=""
            src={signalProtocolImageUrl1}
          />
          <div className={styles.frameParent}>
            <div className={styles.mHaUCuiE2eeWrapper}>
              <b className={styles.mHaU}>Signal protocol, X3DH protocol</b>
            </div>
            <div className={styles.lPhngThcMHaMChNParent}>
              <div className={styles.lPhngThc}>
                Giao thức Signal Protocol, X3DH protocol là giao thức quốc tế
                được hầu hết ứng dụng nhắn tin hàng đầu thế giới sử dụng cho mã
                E2EE.
              </div>
              <b className={styles.readMore}>Read more</b>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default EncryptionContainer;
