import styles from "../css/component_css/ContactContainer.module.css";
import RegistrationSuccessPopup from "./RegistrationSuccessPopup";
import ErrorPopup from "./ErrorPopup";
import { useMemo, useState } from "react";
import axios from "axios";
import { baseURL } from "../config";

const ContactContainer = ({}) => {

  const [isOpenPopup, setIsOpenPopup] = useState();
  const [isOpenError, setIsOpenError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleSend = () => {
    let phone = document.getElementById('questionPhone');
    let email = document.getElementById('questionEmail');
    let question = document.getElementById('question');

    axios.post(baseURL + '/question/add.json', {
      phone: phone.value,
      email: email.value,
      question: question.value
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      setIsOpenPopup(true)
    })
    .catch((error) => {
      if (error.response.data.error == "illegal parameter") setErrorMessage("Vui lòng không bỏ trống thông tin.");
      else if (error.response.data.error == "phone number is invalid") setErrorMessage("Số điện thoại không hợp lệ");
      else if (error.response.data.error == "email is invalid") setErrorMessage("Email không hợp lệ");
      setIsOpenError(true);
    })
  }

  return (
    <div className={styles.contact2}>
      <img className={styles.bgIcon} alt="" src="../_BGcontact.svg" />
      <img className={styles.bgIconmobile} alt="" src="../_BG_mobile.svg" />
      <div className={styles.content}>


        <div className={styles.title}>
          <b className={styles.giiPhpTon}>Bạn cần hỗ trợ?</b>
          <div className={styles.triosGiaiQuytCacVnWrapper}>
            <div className={styles.triosGiaiQuytContainer}>
              <p className={styles.hyGiCu}> Hãy gửi câu hỏi hoặc tính năng bạn quan tâm cho chúng tôi. </p>
              <p className={styles.chngTiS}>
                Chúng tôi sẽ cố gắng phản hồi nhanh nhất có thể.
              </p>
            </div>
          </div>
        </div>


        <form className={styles.form} >
          <div className={styles.inputParent}>
            <div className={styles.input}>
              <div className={styles.inputFormParent}>
                <div className={styles.inputForm}>
                  <div className={styles.hVTn}>Số điện thoại</div>
                      <input id="questionPhone" placeholder="Nhập số điện thoại" className={styles.placeholderParent} required/>
                </div>

                <div className={styles.inputForm}>
                  <div className={styles.hVTn}>Email</div>
                  <input id="questionEmail" placeholder="Nhập email" className={styles.placeholderParent} required/>
                </div>
              </div>

              <div className={styles.inputForm2}>
                <div className={styles.hVTn}>Câu hỏi của bạn</div>
                <input id="question" placeholder="Nhập câu hỏi hay tính năng bạn quan tâm" className={styles.placeholderParent} required/>
              </div>
            </div>
            <div className={styles.error}>
              Vui lòng không để trống các trường thông tin
            </div>
          </div>

          <div className={styles.buttonDesktop} onClick={handleSend}>
              <b className={styles.vuiLngNhp} >Gửi thông tin</b>
          </div>
          
        </form>

        <RegistrationSuccessPopup open={isOpenPopup} onCloseParent={() => setIsOpenPopup(false)} 
        successMessage="Gửi thành công"
        confirmationMessage="Tin nhắn của bạn đã được Trios tiếp nhận. Chúng tôi sẽ liên hệ lại sớm nhất có thể. "
        />
        <ErrorPopup 
          errorStatus = {"Gửi không thành công"}
          errorMessage={errorMessage} 
          openError={isOpenError} 
          onCloseError={() => setIsOpenError(false)}
        />
      </div>
    </div>
  );
};

export default ContactContainer;
