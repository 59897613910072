import styles from "../css/component_css/RegistrationTriosContainer.module.css";
import React, { useEffect, useState } from "react";
import RegistrationSuccessPopup from "./RegistrationSuccessPopup";
import ErrorPopup from "./ErrorPopup";
import axios from "axios";
import { baseURL } from "../config";

const RegistrationTriosContainer = ({ open, initialPackage, onClose }) => {

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenError, setIsOpenError] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  const handleSubmit = () => {
    let phone = document.getElementById("registerPhone").value
    let mail = document.getElementById("registerEmail").value
    let packageChoice = document.getElementById("registerPackage").value

    axios.post(baseURL + '/register.json', {
      phone: phone,
      email: mail,
      package_id: packageChoice
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        setIsOpenPopup(true);
      })
      .catch((error) => {
        if (error.response.data.error == "illegal parameter") setErrorMessage("Vui lòng không bỏ trống thông tin.");
        else if (error.response.data.error == "phone number is invalid") setErrorMessage("Số điện thoại không hợp lệ");
        else if (error.response.data.error == "email is invalid") setErrorMessage("Email không hợp lệ");
        else if (error.response.data.error == "Email is existed!") setErrorMessage("Email đã được sử dụng để đăng ký");
        setIsOpenError(true);
      })
  }


  return (open) ? (
    <div className={styles.popupNgKTrios} id="popupRegister">
      <div className={styles.logoTriosParent}>
        <img className={styles.logoTriosIcon} alt="" src="../logo-trios6.svg" />
        <div className={styles.title}>
          <b className={styles.giiPhpTon}>Đăng ký Trios</b>
          <div className={styles.triosGiaiQuytCacVnWrapper}>
            <div className={styles.triosGiaiQuyt}>
              Vui lòng nhập các thông tin đăng ký bên dưới
            </div>
          </div>
        </div>

        <form className={styles.form}>
          <div className={styles.inputParent}>
            <div className={styles.input}>
              <div className={styles.inputFormParent}>
                <div className={styles.inputForm}>
                  <div className={styles.hVTn}>Số điện thoại</div>
                  <input
                    id="registerPhone"
                    className={styles.placeholderParent}
                    type="tel"
                    maxLength="12"
                    placeholder="Nhập số điện thoại"
                  />
                </div>
                <div className={styles.inputForm}>
                  <div className={styles.hVTn}>Email</div>
                  <input
                    id="registerEmail"
                    className={styles.placeholderParent}
                    type="email"
                    placeholder="Nhập địa chỉ email"
                  />
                </div>
              </div>

              <div className={styles.inputForm2}>
                <div className={styles.hVTn}>Gói đăng ký</div>
                <select name="" id="registerPackage" className={styles.placeholderParent} defaultValue={initialPackage}>
                  <option value={1}>Gói cơ bản</option>
                  <option value={2}>Gói tiêu chuẩn</option>
                  <option value={3}>Gói nâng cao</option>
                </select>

              </div>
            </div>
          </div>

          <button className={styles.buttonDesktop} onClick={handleSubmit} type="button">
            <b className={styles.vuiLngNhp}>Đăng ký</b>
          </button>
        </form>
        <button
          onClick={onClose}
          className={styles.closeIcon}
          src="../close1.svg"
        >
          X
        </button>

        <RegistrationSuccessPopup
          open={isOpenPopup}
          onClose={() => {
            setIsOpenPopup(false);
          }}
          successMessage="Đăng ký thành công"
          confirmationMessage="Thông tin đăng ký của bạn đã được Trios tiếp nhận. Chúng tôi sẽ liên hệ lại sớm nhất có thể. "
        />
        <ErrorPopup
          errorStatus={"Đăng ký không thành công"}
          errorMessage={errorMessage}
          openError={isOpenError}
          onCloseError={() => setIsOpenError(false)}
        />
      </div>
    </div>) : <></>
};

export default RegistrationTriosContainer;
