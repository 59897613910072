import { useMemo, useState } from "react";
import styles from "../css/component_css/SecureDataExchangeContainer.module.css";
import RegistrationTriosContainer from "./RegistrationTriosContainer";

const SecureDataExchangeContainer = ({}) => {
  const downloadIconStyle = useMemo(() => {
    return {
      width: "180px",
      height: "52px",
    };
  }, ["180px", "52px"]);

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <>
      <div className={styles.headerParent}>
        <div className={styles.header}>
          <b className={styles.traoIThng}>
            Trao đổi thông tin trực tuyến an toàn với Trios
          </b>
          <div className={styles.des}>
            <div className={styles.sHuLm}>
              Sở hữu, làm chủ tổng đài/ máy chủ và toàn bộ nội dung trao đổi,
              thông tin và dữ liệu hệ thống.
            </div>
          </div>
        </div>
        <div className={styles.buttonDesktop}>
          {/*<a href={}>*/}
          {/*  <div className={styles.buttonDesktop1}>Đăng ký ngay</div>*/}
          {/*</a>*/}
        </div>
        <div className={styles.triNghimTriosTrnParent}>
          <div className={styles.tiTrios}>Trải nghiệm Trios trên</div>
          <div className={styles.downloadParent}>
            <a href="https://apps.apple.com/vn/app/trios/id6443949955" target="_blank">
              <img
                className={styles.downloadIcon}
                alt=""
                src="../download.svg"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.bglobal.trios&hl=en" target="_blank">
              <img
                className={styles.downloadIcon}
                alt=""
                src="../download1.svg"
              />
            </a>
          </div>
        </div>
      </div>
      <RegistrationTriosContainer
        open={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
      />
    </>
  );
};

export default SecureDataExchangeContainer;
