import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "../css/component_css/Header.module.css";
import MenuPopup from "./MenuPopup"
import { useNavigate } from 'react-router-dom';

const Header = ({ gioi_thieuRef,
  giai_phapRef,
  mo_hinhRef,
  cong_ngheRef,
  bang_giaRef,}) => {
  const history = useNavigate();
  const [logoPath, setLogoPath] = useState(WHITE_LOGO)
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (gioi_thieuRef?.current) {
        let header = document.querySelector("#main_header");
        let element_headers = document.querySelectorAll("a[data-name='element_header']")
        if (window.pageYOffset > 20) {
          setLogoPath(BLUE_LOGO);
          header.className = styles.navigation_scroll;
          element_headers.forEach(el => {
            el.className = styles.element_header_scroll;
          })
        } else {
          setLogoPath(WHITE_LOGO);
          header.className = styles.navigation;
          element_headers.forEach(el => {
            el.className = styles.giiThiu;
          })
        }

        console.log(window.pageYOffset);
        let gt = document.querySelector("#btnGT");
        let gp = document.querySelector("#btnGP");
        let mh = document.querySelector("#btnMH");
        let cn = document.querySelector("#btnCN");
        // let bg = document.querySelector("#btnBG");
        if (window.pageYOffset >= 0 && window.pageYOffset <= 810) {
          gt.className = styles.chooseBtn;
        } else {
          gt.className = styles.element_header_scroll;
          if (window.pageYOffset > 810 && window.pageYOffset <= 2050) {
            gp.className = styles.chooseBtn;
          } else {
            gp.className = styles.element_header_scroll;
            if (window.pageYOffset > 2050 && window.pageYOffset <= 3450) {
              mh.className = styles.chooseBtn;
            } else {
              mh.className = styles.element_header_scroll;
              if (window.pageYOffset > 3450 && window.pageYOffset <= 4300) {
                cn.className = styles.chooseBtn;
              } else {
                cn.className = styles.element_header_scroll;
                // if (window.pageYOffset > 4300 && window.pageYOffset <= 5300) {
                //   bg.className = styles.chooseBtn;
                // } else {
                //   bg.className = styles.element_header_scroll;
                // }
              }
            }
          }
        }
      }
    }

    let header = document.querySelector("#main_header");
    if (header) {
      document.addEventListener("scroll", onScroll);

      return () => {
        document.removeEventListener("scroll", onScroll)
      }
    }
  })

  const menuClick = () => {
    if(isOpenPopup) setIsOpenPopup(false);
    else setIsOpenPopup(true);
  }

  const gtClick = (e) => {
    e.preventDefault();
    if (gioi_thieuRef?.current){
      gioi_thieuRef.current.scrollIntoView({ behavior: "smooth" });
      setIsOpenPopup(false);
    }else {
      history("/")
    }

  }
  const gpClick = (e) => {
    e.preventDefault();
    if (giai_phapRef?.current){
      giai_phapRef.current.scrollIntoView({ behavior: "smooth" });
      setIsOpenPopup(false);
    }else {
      history("/")
    }

  }
  const mhClick = (e) => {
    e.preventDefault();
    if (mo_hinhRef?.current){
      mo_hinhRef.current.scrollIntoView({ behavior: "smooth" });
      setIsOpenPopup(false);
    }else {
      history("/")
    }

  }
  const cnClick = (e) => {
    if (cong_ngheRef?.current){
      e.preventDefault();
      cong_ngheRef.current.scrollIntoView({ behavior: "smooth" });
      setIsOpenPopup(false);
    }else {
      history("/")
    }
  }
  const bgClick = (e) => {
    e.preventDefault();
    if (bang_giaRef?.current){
      bang_giaRef.current.scrollIntoView({ behavior: "smooth" });
      setIsOpenPopup(false);
    }else {
      history("/")
    }
  }



  return (
    <div className={styles.navigation} id="main_header">
      <div className={styles.navigation1}>
      
      <img className={styles.logoTriosIconMobile} alt="" src={logoPath}/>
      <img className={styles.menuIcon} alt="" src="../menu1.svg" onClick={menuClick} />  
      
      
        <div className={styles.content}>
          <img className={styles.logoTriosIcon} alt="" src={logoPath}/>
          
          <div className={styles.frameParent} >
            {gioi_thieuRef?.current && (
            <div className={styles.giiPhpParent}>
              <a href="" onClick={gtClick} className={clsx(styles.chooseBtn)} data-name="element_header" id="btnGT"> Giới thiệu </a>
            </div>)}
            {giai_phapRef?.current && (
            <div className={styles.giiPhpParent}>
              <a href="" onClick={gpClick} className={clsx(styles.giiThiu)} data-name="element_header" id="btnGP">Giải pháp</a>
            </div>)}
            {mo_hinhRef?.current && (
            <div className={styles.giiPhpParent}>
              <a href="" onClick={mhClick} className={clsx(styles.giiThiu)} data-name="element_header" id="btnMH"> Mô hình</a>
            </div>)}
            {cong_ngheRef?.current && (
            <div className={styles.giiPhpParent}>
              <a href="" onClick={cnClick} className={clsx(styles.giiThiu)} data-name="element_header"  id="btnCN"> Công nghệ</a>
            </div>)}

            {/*<div className={styles.giiPhpParent}>*/}
            {/*  <a href="" onClick={bgClick} className={clsx(styles.giiThiu)} data-name="element_header" id="btnBG"> Bảng giá</a>*/}
            {/*</div>*/}

          </div>
          <div className={styles.buttonDesktopParent}>
            <div className={styles.buttonDesktop}>
              <a href="https://github.com/bglapps/trios-desktop/releases/" target="_blank">
                <div className={styles.buttonDesktop1}>
                  Tải Trios
                </div>
              </a>
            </div>
          </div>
          
        </div>
      </div>
      <MenuPopup open={isOpenPopup} 
      gioi_thieu_mnRef = {gtClick}
      giai_phap_mnRef = {gpClick}
      mo_hinh_mnRef = {mhClick}
      cong_nghe_mnRef = {cnClick}
      bang_gia_mnRef = {bgClick}
      />
    </div>
  );
};

export default Header;

const WHITE_LOGO = "../logo-trios.svg";
const BLUE_LOGO = "../logo-trios12.svg";
