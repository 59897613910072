import styles from "../css/component_css/MenuPopup.module.css";
import React, { useEffect, useState } from "react";

const MenuPopup = (props) => {

  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.open]);

  const onScroll = () => {
      props.gioi_thieu_mnRef();
  }

    return (props.open) ? (
      <div className={styles.menu}>
        <div className={styles.menu2}>
          <img className={styles.decor2Icon} alt="" src="../decor-21.svg" />
          <b className={styles.menu3}>Menu</b>
          <div className={styles.content1}>
            <div className={styles.options}>
              <div className={styles.menu11} >
                <b className={styles.tiTrios} onClick={props.gioi_thieu_mnRef}>Giới thiệu</b>
                <img className={styles.vietnamIcon1} alt="" />
              </div>
              <div className={styles.menu11}>
                <b className={styles.tiTrios} onClick={props.giai_phap_mnRef}>Giải pháp</b>
                <img className={styles.vietnamIcon1} alt="" />
              </div>
              <div className={styles.menu11}>
                <b className={styles.tiTrios} onClick={props.mo_hinh_mnRef}>Mô hình</b>
                <img className={styles.vietnamIcon1} alt="" />
              </div>
              <div className={styles.menu11}>
                <b className={styles.tiTrios} onClick={props.cong_nghe_mnRef}>Công nghệ</b>
                <img className={styles.vietnamIcon1} alt="" />
              </div>
              {/*<div className={styles.menu11}>*/}
              {/*  <b className={styles.tiTrios} onClick={props.bang_gia_mnRef}>Bảng giá</b>*/}
              {/*  <img className={styles.vietnamIcon1} alt="" />*/}
              {/*</div>*/}
            </div>
            
          </div>
          <div className={styles.buttonMobile1}>
            <a href=""><b className={styles.tiTrios1}>Tải Trios</b></a>
          </div>
        </div>
      </div>
    ) : "";
  };
  
  export default MenuPopup;
  