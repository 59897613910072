
import TriosContainer from "../components/TriosContainer";
import styles from "../css/main_css/Homepage3.module.css";
import React from 'react';
import Header from "../components/Header";
import { useRef } from "react";
import PolicyContentContainer from "../components/PolicyContentContainer";

const Policy = () => {
    const gioi_thieuRef = useRef(null);
    const giai_phapRef = useRef(null);
    const mo_hinhRef = useRef(null);
    const cong_ngheRef = useRef(null);
    const bang_giaRef = useRef(null);


    return (
        <div >
            <div className={styles.policySection} >
                <Header
                    className={styles.header}
                    gioi_thieuRef={gioi_thieuRef}
                    giai_phapRef={giai_phapRef}
                    mo_hinhRef={mo_hinhRef}
                    cong_ngheRef={cong_ngheRef}
                    bang_giaRef={bang_giaRef}
                />
                <PolicyContentContainer/>
                {/*<div className={styles.headerParent} ref={gioi_thieuRef} dangerouslySetInnerHTML={{ __html: policy }} />*/}

            </div>



            <TriosContainer
                group85457="../group-85457.svg"
                logoTrios="../logo-trios1.svg"
                download="../download2.svg"
                download1="../download3.svg"
                group3982="../group-3982.svg"
            />
        </div>
    );
};

export default Policy;
