import { useMemo } from "react";
import styles from "../css/component_css/SecureCommunicationContainer.module.css";

const SecureCommunicationContainer = ({ propZIndex, }) => {
  const titleStyle = useMemo(() => {
    return {
      zIndex: "1",
    };
  }, ["1"]);

  return (
    <div className={styles.title} style={titleStyle} >
      <p className={styles.giiPhpTonContainer}><strong>Giải pháp toàn diện cho <br /> liên lạc an toàn</strong></p>
      <div className={styles.triosGiaiQuytCacVnWrapper}>
        <div className={styles.triosGiaiQuyt}>
          Trios giải quyết các vấn đề trên như nào? Trios cung cấp giải pháp
          nhắn tin, gọi điện video, âm thanh, gửi file, hội nghị trực tuyến và
          lưu trữ dữ liệu an toàn, đảm bảo các yếu tố sau
        </div>
      </div>
    </div >
  );
};

export default SecureCommunicationContainer;
