import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DeepLink = () => {
  const location = useLocation();

  useEffect(() => {
    var fallbackLink = new URLSearchParams(location.search).get("deeplink_url");
    var isiOS =
        navigator.userAgent.match("iPad") ||
        navigator.userAgent.match("iPhone") ||
        navigator.userAgent.match("iPod"),
      isAndroid = navigator.userAgent.match("Android");

    if (isiOS || isAndroid) {
      document.getElementById("loader").src = fallbackLink;
      fallbackLink = isAndroid
        ? "https://play.google.com/store/apps/details?id=com.bglobal.trios&referrer="+location.search
        : "https://apps.apple.com/vn/app/trios/id6443949955";
    }

    window.setTimeout(function () {
      if (isiOS || isAndroid) {
        setTimeout(function () {
          window.location.replace("/link/v1" + location.search)
        }, 300);
      } else {
        window.location.replace(fallbackLink);
        window.setTimeout(function () {
          window.location.replace("/");
        }, 3000);
      }
    }, 1000);
  }, []);

  return <iframe id="loader"></iframe>;
};

export default DeepLink;
